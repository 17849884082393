<template>
    <div class="app-content">
      <!-- <div style="margin-bottom:20px">
        <el-card class="box-card">
          <b>
            {{title}}
          </b>
        </el-card>
      </div> -->
      <el-form
        ref="ruleForm"
        :model="form"
        :rules="rules"
        label-width="80px"
        label-position="top"
      >
        <el-row type="flex" justify="center">
          <el-col :sm="20" :lg="16">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <b>模板信息</b>
              </div>
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="名称" prop="wjtName">
                    <el-input v-model="form.wjtName" placeholder="请输入" />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="版本类型" prop="wjtType">
                    <el-select
                      style="width: 100%"
                      v-model="form.wjtType"
                      placeholder="请选择"
                      clearable
                    >
                      <el-option
                        v-for="dict in typeList"
                        :key="dict.value"
                        :label="dict.label"
                        :value="dict.value"
                      />
                    </el-select>
                  </el-form-item>
                  <!-- <el-form-item label="活动地点" prop="location">
                                      <el-input v-model="form.location" placeholder="请输入" />
                                  </el-form-item> -->
                </el-col>
                <el-col :span="8">
                  <el-form-item label="业务类型" prop="wjtYwType">
                    <el-select
                      style="width: 100%"
                      v-model="form.wjtYwType"
                      placeholder="请选择"
                      clearable
                    >
                      <el-option
                        v-for="(dict, index) in businessType"
                        :key="index"
                        :label="dict.label"
                        :value="dict.value"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8" style="clear: both">
                  <el-form-item label="关键词" prop="wjtKey">
                    <el-input v-model="form.wjtKey" placeholder="请输入" />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="简介" prop="wjtProfile">
                    <el-input v-model="form.wjtProfile" placeholder="请输入" />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="上传文件" prop="url">
                    <!-- <el-input
                                          v-model="form.wjtProfile"
                                          placeholder="请输入"
                                      /> -->
                    <fileUpload
                      :value="form.url"
                      :fileType="fileType"
                      @input="fileResult"
                    ></fileUpload>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-card>
            <el-card class="box-card bottom-card" style="margin-top: 10px">
              <div slot="header" class="clearfix">
                <b><span style="color: red">*</span> 模板内容</b>
              </div>
              <el-row type="flex" justify="end">
                <el-form-item prop="wjtData" style="width: 100%">
                  <b-code-editor
                    v-model="form.wjtData"
                    height="auto"
                    theme="dracula"
                    :indent-unit="2"
                    ref="editor"
                  ></b-code-editor>
                  <!-- <el-checkbox v-model="form.checked">同步旗下问卷数据 <span style="color:red;">(* 所有旗下问卷数据都会被修改。)</span></el-checkbox> -->
                </el-form-item>
              </el-row>
              <el-button
                type="primary"
                size="small"
                @click="$refs['editor'].formatCode()"
                >手动触发格式化</el-button
              >
              <el-button type="primary" size="small" @click="dialogVisible = true"
                >放大编辑器</el-button
              >
            </el-card>
            <el-card class="box-card bottom-card" style="margin-top: 10px">
              <el-row type="flex" justify="end">
                <!-- <el-button @click="resetForm('ruleForm')">取 消</el-button> -->
                <el-button type="primary" @click="submitForm('ruleForm')"
                  >提 交</el-button
                >
              </el-row>
            </el-card>
          </el-col>
        </el-row>
        <el-dialog
          title="模板内容"
          :visible.sync="dialogVisible"
          width="100%"
          fullscreen
          :before-close="() => (dialogVisible = false)"
          class="dialog"
        >
          <div>
            <b-code-editor
              v-model="form.wjtData"
              height="auto"
              theme="dracula"
              :indent-unit="2"
              ref="editor"
            ></b-code-editor>
          </div>
        </el-dialog>
      </el-form>
    </div>
  </template>
  
  <script>
  import {
    templateGetIdApi,
    templateUpdateApi,
    templateAddApi,
  } from "@/api/templateApi.js";
  import fileUpload from "@/components/FileUpload/index";
  export default {
    components: { fileUpload },
    data() {
      return {
        dialogVisible: false,
        typeList: [
          {
            label: "外贸调研",
            value: "外贸调研",
          },
          {
            label: "商务部标准版本",
            value: "商务部标准版本",
          },
          {
            label: "山东兼容版",
            value: "山东兼容版",
          },
        ],
        businessType: [
          {
            label: "外资",
            value: "WZ",
          },
          {
            label: "外贸",
            value: "WM",
          },
          {
            label: "外经",
            value: "WJ",
          },
        ],
        title: "新增模板",
        form: {
          wjtData:
            '{"title": "常规问卷","debug": "0","history": "1","questions": []}',
        },
        fileType: ["doc", "docx"],
        rules: {
          wjtName: [{ required: true, message: "请输入名称", trigger: "blur" }],
          wjtType: [
            {
              required: true,
              message: "请选择版本类型",
              trigger: "blur",
            },
          ],
          wjtYwType: [
            {
              required: true,
              message: "请选择业务类型",
              trigger: "blur",
            },
          ],
          wjtData: [
            {
              required: true,
              message: "请输入模板内容",
              trigger: "blur",
            },
          ],
          // url: [
          //     {
          //         required: true,
          //         message: '请选择上传文件',
          //         trigger: 'blur',
          //     },
          // ],
        },
      };
    },
    created() {
      if (this.$route.query.id) {
        this.getTemplateGetIdFun();
      }
    },
    methods: {
      async getTemplateGetIdFun() {
        var params = {
          id: this.$route.query.id,
        };
        var res = await templateGetIdApi(params);
        console.log("res", res);
        if (res.code === 200) {
          if (this.$route.query.type == "copy") {
            this.form.wjtData = res.data.wjtData;
          } else {
            this.form = res.data;
          }
        }
      },
      fileResult(value) {
        this.form.url = value;
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.$route.query.type == "copy") {
              this.addTemplateAddApi();
            } else {
              if (this.$route.query.id) {
                this.updateTemplateUpdateApi();
              } else {
                this.addTemplateAddApi();
              }
            }
          } else {
            this.$message({
              type: "error",
              message: "请检查是否有提示选项",
            });
            console.log("error submit!!");
            return false;
          }
        });
      },
      async updateTemplateUpdateApi() {
        delete this.form.time;
        delete this.form.survey;
        var res = await templateUpdateApi(this.form);
        if (res.code === 200) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.$router.go(-1);
        }
      },
      async addTemplateAddApi() {
        var res = await templateAddApi(this.form);
        if (res.code === 200) {
          this.$message({
            message: "提交成功",
            type: "success",
          });
          this.$router.go(-1);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .app-content {
    width: 1200px;
    margin: 20px auto 20px auto;
    /* margin-top: 20px; */
  }
  ::v-deep .el-form-item {
    width: 100%;
  }
  ::v-deep .CodeMirror {
    height: 500px;
  }
  ::v-deep .dialog .CodeMirror {
    height: 87vh;
  }
  </style>
  